import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",

    // flexBasis: '33%',
    fontFamily: "Poppins",
    // color: 'rgb(255, 255, 230)',
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      height: "70vh",
      marginTop: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "70vh",
      marginTop: "100px",
    },
  },
  colorText: {
    color: "#1B6B93",
  },
  container: {
    // textAlign: 'center',
    height: "80vh",
    maxWidth: "85vw",

    // flexWrap: 'nowrap',

    // width: '900wh',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      // flexDirection: 'column',
      marginTop: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "100px",
    },
  },
  mainText: {
    padding: "30px",
    marginBottom: "100px",
  },
  heroImage: {
    position: "relative",
    // width: '10%',
    // height: '10%',
    display: "block",
    marginBottom: "0",
    marginLeft: "150px",
    float: "right",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      marginLeft: "50px",
      marginBottom: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "2px",
      marginBottom: "100px",
    },
  },
  title: {
    // position: 'relative',
    // color: '#8BBCCC',

    fontSize: "3.5rem",
    lineHeight: "70px",
    fontWeight: "500",
    margin: "30px 0px",

    // backgroundColor: '#f3ec78',
    backgroundImage: "linear-gradient(95deg, #8BBCCC, #4C6793, #46531A, rgba(90, 112, 13, 0.1))", //46531A
    backgroundSize: "100%",
    "-webkit-background-clip": "text",
    "-moz-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "-moz-text-fill-color": "transparent",

    // flexWrap: 'nowrap',
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.0rem",
      backgroundSize: "100%",
    },
  },
  shortbio: {
    // color: '#e7e7e7', //8BBCCC
    fontSize: "1.5rem",
    margin: "10px 5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.0rem",
    },
  },
  shortbiosub: {
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7rem",
    },
  },
}));

export default function Main() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="main">
      <div className={classes.container}>
        <div>
          <div className={classes.title}>Welcome!</div>
          <div className={classes.shortbio}>
            I am a Software engineer and am currently focused on developing Web3 Blockchain Decentralized Apps and integrating them with Oracles and the
            Decentralized File System IPFS.
            <p /> Generative AI is of interest as well. I am building on my previous exposure to Machine Learning and Deep Learning to expand into this area.
            <p />I also have a passion for building user-friendly and responsive apps.
            <p className={classes.shortbiosub}>* The certificates are attached under the Education section </p>
          </div>
        </div>
        <div className={classes.heroImage}>
          <img src="/assets/main-image.png" alt="tech"></img>
        </div>
      </div>
    </div>
  );
}
