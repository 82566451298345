import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "80vh",
    maxWidth: "100vw",
    marginTop: "50px",
    marginBottom: "20px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    // flexBasis: '33%',
    fontFamily: "Poppins",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.0rem",
    },
  },
  title: {
    fontSize: "3.5rem",
    lineHeight: "70px",
    fontWeight: "500",
    margin: "10px 0",
    backgroundImage: "linear-gradient(45deg, #8BBCCC, #4C6793, #1B6B93)",
    backgroundSize: "100%",
    "-webkit-background-clip": "text",
    "-moz-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "-moz-text-fill-color": "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.0rem",
      marginBottom: "0px",
    },
  },
  eduText: {
    margin: "0 3px",
    fontSize: "1.4em",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
      margin: "0",
    },
  },
  mainText: {
    padding: "30px",
    marginBottom: "100px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      marginBottom: "50px",
    },
  },
  heroImageWrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      //   // maxwidth: '300px',
      //   // maxWidth: '20vw',
      // backgroundColor: 'lightpink',
      width: "100%",
      marginTop: "40px",
      marginBottom: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      //   // maxwidth: '300px',
      //   // maxWidth: '20vw',
      // backgroundColor: 'lightpink',
      width: "100%",
      marginTop: "20px",
      marginBottom: "70px",
    },
  },
  heroImage: {
    position: "relative",
    width: "100%", //50%
    height: "530px",
    // display: 'block',
    marginBottom: "0",
    [theme.breakpoints.down("md")]: {
      //   // maxwidth: '300px',
      //   // maxWidth: '20vw',
      // backgroundColor: 'beige',
      width: "100%", //50%
      // marginRight: '0',
    },
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: 'lightpink',
      // width: '100%',
      width: "500px",
      height: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      // backgroundColor: 'lightblue',
      // width: '100%',
      width: "400px",
      height: "450px",
    },
  },
  link: {
    // fontFamily: "Poppins",
    color: "inherit",
    // fontWeight: "bold",
  },
  cube: {
    position: "absolute",
    width: "80px",
    height: "80px",
    transform: "rotate(-25deg) skew(25deg)",
    boxShadow: "-80px 80px 20px rgb(100 100 100/ 35%)",
    "&:before": {
      position: "absolute",
      content: '""',
      width: "21px",
      height: "100%",
      transform: "skewY(-45deg)",
      left: "-20px",
      bottom: "-10px",
    },
    "&:after": {
      position: "absolute",
      content: '""',
      width: "100%",
      height: "21px",
      transform: "skewX(-45deg)",
      left: "-10px",
      bottom: "-20px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60px",
      height: "60px",
    },
  },
  java: {
    left: "100px",
    bottom: "280px",
    backgroundImage: "url(/images/java-square.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(162, 205, 176)",
    // boxShadow: '-60px 60px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(177, 220, 176)",
    },
    "&:after": {
      backgroundColor: "rgb(190, 250, 176)",
    },
    [theme.breakpoints.down("md")]: {
      left: "50px",
      bottom: "330px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "40px",
      bottom: "330px",
    },
  },
  solidity: {
    left: "250px",
    bottom: "380px",
    backgroundImage: "url(/images/solidity.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(76, 103, 147)",
    // boxShadow: '-100px 100px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(83, 113, 164)",
    },
    "&:after": {
      backgroundColor: "rgb(115, 157, 230)",
    },
    [theme.breakpoints.down("md")]: {
      left: "180px",
      bottom: "440px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "140px",
      bottom: "400px",
    },
  },
  web3js: {
    left: "450px",
    bottom: "340px",
    backgroundImage: "url(/images/web3js.png)",
    backgroundSize: "cover",
    // backgroundColor: "rgb(162, 205, 176)",
    backgroundColor: "rgb(22, 124, 144)",
    // boxShadow: '-40px 40px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(24, 145, 174)",
    },
    "&:after": {
      backgroundColor: "rgb(30, 187, 225)",
    },
    [theme.breakpoints.down("md")]: {
      left: "350px",
      bottom: "370px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "270px",
      bottom: "420px",
    },
  },
  react: {
    left: "450px",
    bottom: "110px",
    backgroundImage: "url(/images/react-square.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(119, 193, 227)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(124, 210, 227)",
    },
    "&:after": {
      backgroundColor: "rgb(158, 220, 221)",
    },
    [theme.breakpoints.down("md")]: {
      left: "220px",
      bottom: "290px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "180px",
      bottom: "290px",
    },
  },
  android: {
    left: "330px",
    bottom: "40px",
    backgroundImage: "url(/images/android-square.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(27, 107, 147)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(47, 117, 147)",
    },
    "&:after": {
      backgroundColor: "rgb(77, 127, 147)",
    },
    [theme.breakpoints.down("md")]: {
      left: "250px",
      bottom: "170px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "170px",
      bottom: "90px",
    },
  },
  sql: {
    left: "590px",
    bottom: "10px",
    backgroundImage: "url(/images/sql-square.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(136, 164, 124)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(146, 144, 124)",
    },
    "&:after": {
      backgroundColor: "rgb(186, 184, 124)",
    },
    [theme.breakpoints.down("md")]: {
      left: "50px",
      bottom: "190px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "50px",
      bottom: "190px",
    },
  },
  ethersjs: {
    left: "320px",
    bottom: "230px",
    backgroundImage: "url(/images/ethersjs.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(34, 124, 112)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(44, 144, 112)",
    },
    "&:after": {
      backgroundColor: "rgb(84, 144, 112)",
    },
    [theme.breakpoints.down("md")]: {
      left: "370px",
      bottom: "210px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "290px",
      bottom: "90px",
    },
  },
  python: {
    left: "320px",
    bottom: "-90px",
    backgroundImage: "url(/images/python-square.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(34, 124, 112)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(44, 144, 112)",
    },
    "&:after": {
      backgroundColor: "rgb(84, 144, 112)",
    },
    [theme.breakpoints.down("md")]: {
      left: "570px",
      bottom: "170px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "170px",
      bottom: "190px",
    },
  },
  kotlin: {
    left: "150px",
    bottom: "10px",
    backgroundImage: "url(/images/kotlin-square.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(21, 152, 149)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(31, 170, 149)",
    },
    "&:after": {
      backgroundColor: "rgb(91, 182, 149)",
    },
    [theme.breakpoints.down("md")]: {
      left: "60px",
      bottom: "60px",
    },
  },
  ethereum: {
    left: "560px",
    bottom: "200px",
    backgroundImage: "url(/images/ethereum.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(120, 149, 203)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(144, 155, 201)",
    },
    "&:after": {
      backgroundColor: "rgb(188, 200, 221)",
    },
    [theme.breakpoints.down("md")]: {
      left: "490px",
      bottom: "300px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "290px",
      bottom: "310px",
    },
  },
  javascript: {
    left: "150px",
    bottom: "150px",
    backgroundImage: "url(/images/javascript-square.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(120, 149, 203)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(144, 155, 201)",
    },
    "&:after": {
      backgroundColor: "rgb(188, 200, 221)",
    },
    [theme.breakpoints.down("md")]: {
      left: "470px",
      bottom: "90px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "270px",
      bottom: "210px",
    },
  },
  css: {
    left: "500px",
    bottom: "-100px",
    backgroundImage: "url(/images/css-square.png)",
    backgroundSize: "cover",
    backgroundColor: "rgb(120, 149, 203)",
    // boxShadow: '-80px 80px 20px rgb(50 50 50/ 15%)',
    "&:before": {
      backgroundColor: "rgb(144, 155, 201)",
    },
    "&:after": {
      backgroundColor: "rgb(188, 200, 221)",
    },
    [theme.breakpoints.down("md")]: {
      left: "290px",
      bottom: "60px",
    },
    [theme.breakpoints.down("xs")]: {
      left: "220px",
      bottom: "0px",
    },
  },
}));

export default function Education() {
  const classes = useStyles();
  return (
    <div className={classes.root} id="education">
      <div className={classes.mainText}>
        <h1 className={classes.title}>Education & Skills</h1>
        <br />
        <div className={classes.eduText}>
          <List>
            <ListItem>
              &#8277; &nbsp;
              <ListItemText disableTypography primary="Master's of Science in Computer Science" />
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <ListItemText disableTypography primary="Master's of Business Administration" />
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <Link href="/assets/self-driving-cars-engineer-certificate.pdf" target="_blank" underline="always" className={classes.link}>
                <ListItemText disableTypography primary="Self Driving Car Engineer Certificate" />
              </Link>
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <Link href="/assets/android-developer-certificate.pdf" target="_blank" underline="always" className={classes.link}>
                <ListItemText disableTypography primary="Android App Development Certificate" />
              </Link>
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <Link href="/assets/fullstack-developer-certificate.pdf" target="_blank" underline="always" className={classes.link}>
                <ListItemText disableTypography primary="Full Stack Javascript Developer Certificate" />
              </Link>
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <Link href="/assets/google-data-nalytics-certificate.pdf" target="_blank" underline="always" className={classes.link}>
                <ListItemText disableTypography primary="Google Data Analytics Certificate" />
              </Link>
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <Link href="/assets/blockchain-developer-certificate.pdf" target="_blank" underline="always" className={classes.link}>
                <ListItemText disableTypography primary="Blockchain Developer Certificate" />
              </Link>
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <ListItemText disableTypography primary="Certified Blockchain Solutions Architect ~ In progress" />
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <ListItemText disableTypography primary="Generative AI ~ In progress" />
            </ListItem>
            <ListItem>
              &#8277; &nbsp;
              <ListItemText disableTypography primary="Google UX Designer Certificate ~ Next" />
            </ListItem>
          </List>
        </div>
      </div>

      <div className={classes.heroImageWrapper}>
        <div className={classes.heroImage}>
          <div className={`${classes.cube} ${classes.ethereum}`}></div>
          <div className={`${classes.cube} ${classes.css}`}></div>
          <div className={`${classes.cube} ${classes.javascript}`}></div>
          <div className={`${classes.cube} ${classes.react}`}></div>
          <div className={`${classes.cube} ${classes.android}`}></div>
          <div className={`${classes.cube} ${classes.java}`}></div>
          <div className={`${classes.cube} ${classes.sql}`}></div>
          <div className={`${classes.cube} ${classes.python}`}></div>
          <div className={`${classes.cube} ${classes.ethersjs}`}></div>
          <div className={`${classes.cube} ${classes.kotlin}`}></div>
          <div className={`${classes.cube} ${classes.web3js}`}></div>
          <div className={`${classes.cube} ${classes.solidity}`}></div>
        </div>
      </div>
    </div>
  );
}
