import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./../App.css";

import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";

import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Collapse } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "400px",
    height: "450px",
    // background: 'rgb(29, 202, 205, 0.75)',
    // background: 'rgba(29, 102, 105, 0.5)',
    // background: 'rgba(27, 107, 147, 0.75)',
    background: "rgba(76, 103, 147, 0.75)",
    // background: 'rgba(255, 255, 255, 0.95)',
    // border: 'none',
    // boxShadow: '0 1px 20px 0 rgba(0, 0, 0, 0.5)',
    // boxShadow: '0 1px 20px 0 rgb(100 100 100/ 0.75)',
    padding: "10px",
  },
  media: {
    width: "360px",
    height: "300px",
    backgroundSize: "360px 240px",
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: "1.2rem",
    color: "#fff",
    textAlign: "center",
    marginBottom: "20px",
  },
  desc: {
    fontFamily: "Poppins",
    fontSize: "1.0rem",
    color: "#fff",
    textAlign: "center",
    marginBottom: "10px",
  },
  tools: {
    fontFamily: "Poppins",
    fontSize: "1.0rem",
    color: "rgba(117, 231, 153, 0.8)",
    textAlign: "center",
    // marginTop: "20px",
    // paddingTop: "20px",
    fontWeight: "bold",
  },
  external: {
    fontFamily: "Poppins",
    fontSize: "1.5rem",
    color: "#87C4FF",
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "-20px",
    fontWeight: "bold",
  },
  link: {
    fontFamily: "Poppins",
    fontSize: "1.1rem",
    // color: 'rgb(126, 194, 242)',
    // color: 'rgb(63, 163, 227)',
    color: "rgb(138, 218, 229)",
  },
  space: {
    marginRight: "20px",
    marginLeft: "20px",
  },
}));

export default function ProjectCard({ project, checked, timeOut }) {
  const classes = useStyles();

  // Handles the resources links: Github and/or Website URLs
  var resources = "";
  if (project.website !== "" && project.github !== "") {
    resources = (
      <div>
        <Link href={project.github} variant="body2" target="_blank" underline="none" className={classes.external}>
          Details
        </Link>
        <span className={classes.space}>|</span>
        <Link href={project.website} variant="body2" target="_blank" underline="none" className={classes.external}>
          Site
        </Link>
      </div>
    );
  } else if (project.website !== "" && project.github === "") {
    resources = (
      <Link href={project.website} variant="body2" target="_blank" underline="none" className={classes.external}>
        Site
      </Link>
    );
  } else if (project.website === "" && project.github !== "") {
    resources = (
      <Link href={project.github} variant="body2" target="_blank" underline="none" className={classes.external}>
        Details
      </Link>
    );
  }
  // End of handleing the resources links

  console.log("timeOut:");
  console.log(timeOut, checked);
  return (
    <Collapse in={checked} {...(checked ? { timeout: timeOut } : { timeout: 0 })}>
      <div class="shadow">
        {/*<Link
          href={project.link}
          variant="body2"
          target="_blank"
          underline="none"
          onClick={() => {
            console.info("button.");
          }}
          className={classes.link}
        >*/}
        <Card className={classes.root}>
          <CardMedia className={classes.media} image={project.imageUrl} title="Click to go to either the Web site or the Github Repo" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h1" className={classes.title}>
              {project.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.desc}>
              {project.description}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.tools}>
              {project.tools}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className={classes.external}>
              {resources}
            </Typography>
          </CardContent>
        </Card>
        {/*</Link>*/}
      </div>
    </Collapse>
  );
}
