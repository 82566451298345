import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProjectCard from "./ProjectCard";
import projects from "../static/projects";
import useWindowPosition from "../hook/useWindowPosition";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    maxWidth: "100vw",
    marginTop: "10px",
    paddingBottom: "40px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    fontFamily: "Poppins",
    alignItems: "center",
    justifyContent: "center",
    // flexBasis: '33%',
    [theme.breakpoints.down("md")]: {
      marginTop: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "200px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "70px",
    },
  },
  title: {
    fontSize: "3.5rem",
    lineHeight: "70px",
    fontWeight: "500",
    // margin: '10px 0px',
    width: "100%",
    // marginLeft: '30px',
    paddingLeft: "130px",
    textAlign: "left",

    // backgroundColor: '#f3ec78',
    backgroundImage: "linear-gradient(45deg, #8BBCCC, #4C6793, #1B6B93)",
    backgroundSize: "50%",
    "-webkit-background-clip": "text",
    "-moz-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "-moz-text-fill-color": "transparent",

    // flexWrap: 'nowrap',
    [theme.breakpoints.down("md")]: {
      fontSize: "2.5rem",
      width: "100%",
      paddingLeft: "0",
      textAlign: "center",
      backgroundSize: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem",
      width: "100%",
      paddingLeft: "0",
      textAlign: "center",
      backgroundSize: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.0rem",
      width: "100%",
      textAlign: "center",
      paddingLeft: "0",
      backgroundSize: "100%",
    },
  },
  mainText: {
    paddingLeft: "30px",
    // marginBottom: '10px',
    marginLeft: "80px",
  },
  projects: {
    // minHeight: '100vh',
    // maxWidth: '100vw',
    // marginTop: '10px',
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    // flexBasis: '33%',
  },
}));

export default function Projects() {
  const classes = useStyles();
  const checked = useWindowPosition("main");

  return (
    <div className={classes.root} id="projects">
      <div className={classes.title}>Sample Projects</div>
      <div className={classes.projects}>
        <ProjectCard project={projects[0]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[1]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[2]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[3]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[4]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[5]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[6]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[7]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[8]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[9]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[10]} checked={checked} timeOut={2000} />
        <ProjectCard project={projects[11]} checked={checked} timeOut={2000} />
      </div>
    </div>
  );
}
